.pill {
  -ms-flex-align: center;
      align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  color: rgba(0, 9, 76, 0.56);
  display: -ms-flexbox;
  display: flex;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 700;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  padding: 17px;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-transition: background-color 0.2s, color 0.2s;
  -o-transition: background-color 0.2s, color 0.2s;
  transition: background-color 0.2s, color 0.2s;
}
.pill:hover {
  background-color: #edf2f8;
  color: rgba(0, 9, 76, 0.56);
  color: rgba(24, 27, 38, 0.84);
}

.pill--active {
  background-color: #edf2f8;
  color: rgba(0, 9, 76, 0.56);
}
