.ios-terminal__header {
  -ms-flex-align: center;
      align-items: center;
  background-color: #f4f7fb;
  display: -ms-flexbox;
  display: flex;
}

.ios-terminal__dot {
  border-radius: 50%;
  height: 12px;
  margin-left: 8px;
  width: 12px;
}

.ios-terminal__dot--error {
  background-color: #ff455b;
}

.ios-terminal__dot--warning {
  background-color: #fdbd39;
}

.ios-terminal__dot--success {
  background-color: #48da90;
}
