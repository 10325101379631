.blog-index {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}

.blog-index__header {
  padding-bottom: 212px;
  padding-top: 188px;
}

.blog-index__tab {
  margin: 19px 0;
  padding: 9px 23px;
}
@media (max-width: 480px) {
  .blog-index__tab {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 480px) {
  .blog-index__tab:first-child {
    margin-right: 16px;
  }
}
@media (max-width: 800px) {
  .blog-index__tab:first-child {
    margin-bottom: 0;
  }
}

.blog-index__tab-wrapper {
  border-bottom: 1px solid #f4f7fb;
  border-top: 1px solid #f4f7fb;
  text-align: center;
  width: 700px;
}
@media (max-width: 800px) {
  .blog-index__tab-wrapper {
    width: 100%;
  }
}

.blog-index__read-more-button {
  display: block;
  width: 260px;
  margin-top: -24px;
  margin-bottom: 36px;
}
