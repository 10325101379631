.heading5 {
  color: #4d4e7f;
  font-family: "Lato", sans-serif;
  font-size: 20px;
  font-weight: 700;
  height: 24px;
  line-height: 24px;
  margin: 0 0 16px 0;
}
@media (max-width: 800px) {
  .heading5 {
    font-size: 16px;
  }
}
