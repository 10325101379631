.dropdown small {
  font-size: 14px;
  margin-left: 5px;
}

.Select .Select-control {
  background-color: #f4f7fb;
  border-radius: 4px;
  border: 1px solid rgba(74, 157, 255, 0.6);
  cursor: pointer;
  height: 50px;
}
.Select .Select-control .Select-input {
  height: 50px;
}
.Select .Select-control .Select-input input {
  color: #fff;
  line-height: 50px;
  padding: 0;
}
.Select .Select-value {
  border-radius: 2px;
  color: #4a9dff;
}
.Select .Select-value .Select-value-icon {
  border: 0;
  float: right;
  line-height: 28px;
  margin: 0 5px;
  padding: 0;
  position: relative;
  visibility: hidden;
  width: 25px;
}
.Select .Select-value .Select-value-icon::after {
  color: #4a9dff;
  content: "\f036";
  font-size: 18px;
  left: 50%;
  letter-spacing: 1px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate -50% -50%;
      -ms-transform: translate -50% -50%;
          transform: translate -50% -50%;
  -webkit-transition: color 150ms ease-in-out;
  -o-transition: color 150ms ease-in-out;
  transition: color 150ms ease-in-out;
  visibility: visible;
}
.Select .Select-value .Select-value-icon:hover::after {
  color: #4a9dff;
}
.Select .Select-value .Select-value-label {
  color: #4a9dff !important;
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 50px;
}
.Select--single.is-open > .Select-control .Select-value .Select-value-label {
  color: #fff !important;
}
.Select .Select-arrow {
  border-top-color: #4a9dff;
}
.Select.is-focused .Select-control {
  border: 1px solid rgba(74, 157, 255, 0.6);
}
.Select.is-focused:not(.is-open) .Select-control {
  background-color: #4a9dff;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.Select.has-value > .Select-control, .Select.has-value.is-pseudo-focused > .Select-control {
  background-color: #f4f7fb;
}
.Select.has-value > .Select-control .Select-value .Select-value-label, .Select.has-value.is-pseudo-focused > .Select-control .Select-value .Select-value-label {
  color: #4a9dff !important;
  font-size: 18px;
}
.Select.is-open .Select-control {
  background-color: #4a9dff;
  -webkit-box-shadow: inset 0 0 8px 0 rgba(0, 0, 0, 0.08);
          box-shadow: inset 0 0 8px 0 rgba(0, 0, 0, 0.08);
}
.Select.is-open .Select-control .Select-arrow {
  border-color: transparent transparent #fff;
}
.Select .Select-menu-outer {
  border-radius: 2px;
  border: 0;
  -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.05);
          box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.05);
  margin-top: 2px;
  z-index: 6;
}
.Select .Select-menu-outer .Select-menu {
  padding: 13px;
}
.Select .Select-option {
  color: rgba(32, 37, 50, 0.66);
}
.Select .Select-option.is-focused {
  background-color: rgba(74, 157, 255, 0.1);
}
.Select .Select-option.is-focused .Select-icon {
  color: #4a9dff;
}
.Select .Select-option.is-disabled {
  color: rgb(32, 37, 50);
}
.Select .Select-option.is-disabled .Select-icon {
  visibility: hidden;
  opacity: 0;
}
.Select .Select-icon {
  color: #4a9dff;
}
.Select .Select-placeholder {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #4a9dff;
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 40px;
  padding: 0 12px;
}
.Select.Select--multi .Select-control {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  min-height: 40px;
}
.Select.Select--multi .Select-multi-value-wrapper {
  -ms-flex-positive: 1;
      flex-grow: 1;
}
.Select.Select--multi .Select-arrow-zone {
  display: block;
}
.Select.Select--multi .Select-option.is-disabled {
  color: rgb(32, 37, 50);
  cursor: default;
}
.Select.Select--multi .Select-option.is-disabled.is-focused {
  background-color: #4a9dff;
  color: #fff;
}
.Select.Select--multi .Select-value {
  margin-bottom: 3px;
  margin-top: 3px;
}
.Select.Select--multi .Select-value-label {
  padding: 0 0 0 1rem;
}
