.logo {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 800px) {
  .logo__icon {
    height: 25px;
  }
}

.logo__text {
  font-size: 24px;
  font-weight: 900;
  margin-bottom: 5px;
  margin-left: 11px;
}
@media (max-width: 800px) {
  .logo__text {
    font-size: 18px;
    margin-left: 0;
  }
}
