.schema {
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  max-width: 1100px;
}

.schema__toc-wrapper {
  -ms-flex: 1 1;
      flex: 1 1;
  min-width: 188px;
}
@media (max-width: 800px) {
  .schema__toc-wrapper {
    display: none;
  }
}

.schema__toc-wrapper--fixed {
  position: fixed;
  top: 0px;
}

.schema__toc-header {
  border-bottom: 1px solid rgba(32, 37, 50, 0.14);
  color: rgba(32, 37, 50, 0.7);
  font-family: "Lato", sans-serif;
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 15px;
}

.schema__tables-count {
  background-color: #4a9dff;
  border-radius: 3px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #fff;
  height: 32px;
  margin-right: 7px;
  padding: 5px 7px;
  width: 38px;
}

.schema__main {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 4 1;
      flex: 4 1;
  -ms-flex-pack: end;
      justify-content: flex-end;
}
@media (max-width: 800px) {
  .schema__main {
    -ms-flex: 1 1;
        flex: 1 1;
    width: 95%;
  }
}

.schema__tables-wrapper {
  margin-right: 10px;
  width: 800px;
}
@media (max-width: 1000px) {
  .schema__tables-wrapper {
    width: 700px;
  }
}
@media (max-width: 900px) {
  .schema__tables-wrapper {
    width: 600px;
  }
}
@media (max-width: 800px) {
  .schema__tables-wrapper {
    width: 95%;
  }
}

.schema__table {
  margin-bottom: 25px;
}

.schema__refinements {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  margin-bottom: 15px;
}
@media (max-width: 800px) {
  .schema__refinements {
    -ms-flex-pack: start;
        justify-content: flex-start;
  }
}

.schema__schema-selector {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  width: 300px;
}

.schema__schema-dropdown {
  margin-left: 15px;
  width: 170px;
}

.schema__current-version-span {
  font-size: 10px;
  margin-left: 5px;
}

.schema__filters {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin-bottom: 15px;
}
@media (max-width: 800px) {
  .schema__filters {
    -ms-flex-pack: start;
        justify-content: flex-start;
  }
}

.schema__platform-filter {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  width: 450px;
}
@media (max-width: 800px) {
  .schema__platform-filter {
    display: block;
  }
}

.schema__platform-dropdown {
  position: relative;
  top: 2px;
}

.schema__restore-default-button {
  font-size: 15px;
  margin-right: 5px;
}
@media (max-width: 800px) {
  .schema__restore-default-button {
    font-size: 12px;
  }
}
