.heading1 {
  color: #00094c;
  font-family: "Lato", sans-serif;
  font-size: 46px;
  font-weight: 900;
  margin: 0;
}
@media (max-width: 800px) {
  .heading1 {
    font-size: 24px;
    text-align: center;
  }
}
