.platform-dropdown {
  display: inline-block;
}
@media (max-width: 800px) {
  .platform-dropdown {
    display: block;
  }
}

.platform-dropdown__circle {
  border-radius: 50%;
  background-color: #4a9dff;
  display: inline-block;
  height: 20px;
  margin-left: 6px;
  width: 20px;
}

.platform-dropdown__form {
  background-color: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.07), 0 21px 33px 0 rgba(48, 48, 91, 0.11);
          box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.07), 0 21px 33px 0 rgba(48, 48, 91, 0.11);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
  position: absolute;
  width: 300px;
  z-index: 1;
}
@media (max-width: 800px) {
  .platform-dropdown__form {
    right: -62px;
  }
}

.platform-dropdown__text {
  background-color: transparent;
  border: 0;
  color: #4a9dff;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  outline: none;
}
@media (max-width: 800px) {
  .platform-dropdown__text {
    background-color: transparent;
    padding-left: 0;
  }
}
