.terminal-wrapper {
  background-color: #fff;
  border-radius: 3px;
  -webkit-box-shadow: 0 2px 3px 0 rgba(32, 37, 50, 0.05), 0 5px 15px 0 rgba(32, 37, 50, 0.14), 0 21px 33px 0 rgba(48, 48, 91, 0.11);
          box-shadow: 0 2px 3px 0 rgba(32, 37, 50, 0.05), 0 5px 15px 0 rgba(32, 37, 50, 0.14), 0 21px 33px 0 rgba(48, 48, 91, 0.11);
}
@media (min-width: 801px) {
  .terminal-wrapper {
    width: 700px;
  }
}
