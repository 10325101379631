.hamburger-menu {
  background-color: transparent;
  border: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  height: 24px;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 0;
  width: 26px;
}
.hamburger-menu:hover {
  cursor: pointer;
}

.hamburger-menu__bar {
  background-color: #00094c;
  display: block;
  height: 4px;
  width: 26px;
}
