.heading3 {
  color: #00094c;
  font-family: "Lato", sans-serif;
  font-size: 24px;
  font-weight: 700;
  height: 32px;
  line-height: 32px;
  margin-bottom: 16px;
  text-align: center;
}
@media (max-width: 800px) {
  .heading3 {
    font-size: 16px;
    font-weight: 900;
    height: 24px;
    line-height: 1.5;
  }
}
