.blog-post {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 700px;
}
@media (max-width: 800px) {
  .blog-post {
    width: 600px;
  }
}
@media (max-width: 620px) {
  .blog-post {
    width: 400px;
  }
}
@media (max-width: 480px) {
  .blog-post {
    padding: 0 15px;
    width: 100%;
  }
}
.blog-post article {
  margin-top: 36px;
  margin-bottom: 52px;
}
.blog-post article a {
  color: #4a9dff;
  font-weight: 700;
  text-decoration: none;
}
.blog-post article h2 {
  color: rgba(32, 37, 50, 0.8);
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 31px;
  text-align: center;
}
.blog-post article p {
  color: rgba(32, 37, 50, 0.8);
  line-height: 1.56;
  font-size: 18px;
}
@media (max-width: 800px) {
  .blog-post article p {
    font-size: 16px;
  }
}
.blog-post article p code {
  background-color: #f4f7fb;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #ff3975;
  font-family: "Source Code Pro", monospace;
  font-size: 16px;
  padding: 2px 4px;
}
.blog-post article pre {
  background-color: #f4f7fb;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 14px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.blog-post article pre code {
  color: rgba(32, 37, 50, 0.8);
  font-size: 14px;
  font-family: "Source Code Pro", monospace;
}
.blog-post article p > img:first-child {
  display: block;
  height: auto;
  margin: 0 auto;
  max-width: 90%;
}
.blog-post article ul, .blog-post article ol {
  color: rgba(32, 37, 50, 0.8);
  font-size: 18px;
  margin-left: 42px;
}
@media (max-width: 800px) {
  .blog-post article ul, .blog-post article ol {
    font-size: 16px;
  }
}
.blog-post article ul li, .blog-post article ol li {
  color: rgba(32, 37, 50, 0.8);
  line-height: 1.56;
  margin-bottom: 20px;
}
.blog-post article ul li:last-child, .blog-post article ol li:last-child {
  margin-bottom: 0;
}
.blog-post h1 {
  color: #4a9dff;
  font-size: 32px;
  margin-bottom: 34px;
  margin-top: 50px;
  text-align: center;
}

.blog-post__author {
  color: #202532;
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 0;
  text-align: center;
}

.blog-post__date {
  color: rgba(32, 37, 50, 0.5);
  font-size: 16px;
  margin-bottom: 24px;
  margin-top: 5px;
  text-align: center;
}

.blog-post__title-link {
  text-decoration: none;
}
