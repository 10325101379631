.not-found__heading-1 {
  margin-top: 140px;
  text-align: center;
}
@media (max-width: 800px) {
  .not-found__heading-1 {
    margin-top: 80px;
  }
}

.not-found__ios-terminal {
  margin: 90px auto 52px;
}
@media (max-width: 800px) {
  .not-found__ios-terminal {
    margin: 50px auto 30px;
    width: 90%;
  }
}

.not-found__ios-terminal .ios-terminal__body {
  padding-bottom: 20px;
}

.not-found__monospace-dark {
  color: #00094c;
  font-weight: 900;
}
@media (max-width: 800px) {
  .not-found__monospace-dark {
    font-size: 6px;
    line-height: 1em;
  }
}

.not-found__take-me-back {
  -ms-flex-align: center;
      align-items: center;
  color: #4a9dff;
  display: -ms-flexbox;
  display: flex;
  font-family: "Lato", sans-serif;
  font-size: 20px;
  font-weight: 900;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin: 0 auto;
  text-decoration: none;
  width: 335px;
}
