.platform-form__input-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin: 25px 20px;
  white-space: nowrap;
}

.platform-form__input-wrapper--distinct {
  border-bottom: 2px solid #f4f7fb;
  margin: 0;
  padding: 15px 20px;
}
