.tab {
  background-color: #f4f7fb;
  border: 1px solid transparent;
  border-radius: 6px;
  color: rgba(0, 18, 95, 0.4);
  font-family: "Lato", sans-serif;
  font-weight: 700;
  height: 50px;
  outline: none;
  padding: 0 16px;
  -webkit-transition: background-color 0.2s, color 0.2s, border-color 0.2s;
  -o-transition: background-color 0.2s, color 0.2s, border-color 0.2s;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s;
}

.tab:hover {
  background-color: #4a9dff;
  border-color: #4a9dff;
  color: #fff;
  cursor: pointer;
}

.tab--active {
  background-color: #fff;
  border-color: #4a9dff;
  color: #4a9dff;
}

.tab--small {
  font-size: 18px;
}

.tab--large {
  font-size: 24px;
  height: 70px;
  line-height: 70px;
}
