.h1-super-heading {
  color: #4a9dff;
  font-family: "Lato", sans-serif;
  font-size: 24px;
  font-weight: 900;
  height: 38px;
  line-height: 38px;
  margin: 0;
}
@media (max-width: 800px) {
  .h1-super-heading {
    font-size: 18px;
    height: 28px;
    line-height: 28px;
    text-align: center;
  }
}
