.home__computer-wrapper {
  display: inline-block;
  position: relative;
}
@media (max-width: 800px) {
  .home__computer-wrapper {
    width: 268px;
  }
}

.home__frame-overlay {
  left: 23px;
  position: absolute;
  z-index: 2;
}
@media (max-width: 800px) {
  .home__frame-overlay {
    width: 297px;
    left: -14px;
    top: 24px;
  }
}

@media (max-width: 800px) {
  .home__hero-computer {
    left: -30px;
    position: relative;
    width: 330px;
  }
}

.home__heading-wrapper {
  padding-bottom: 30px;
}

.home__heading-content-wrapper {
  max-width: 700px;
  margin: 188px auto 0 auto;
}
@media (max-width: 800px) {
  .home__heading-content-wrapper {
    margin-top: 80px;
    max-width: 100%;
  }
}

.home__section {
  padding: 75px 15px;
}

.home__hero-section {
  overflow: hidden;
  position: relative;
  text-align: center;
  -webkit-mask-image: -webkit-gradient(linear, left top, right bottom, color-stop(0, rgba(0, 0, 0, 0)), color-stop(0.35, rgb(0, 0, 0)), color-stop(0.5, rgb(0, 0, 0)), color-stop(0.65, rgb(0, 0, 0)), color-stop(1, rgba(0, 0, 0, 0)));
}

.home__icon-wrapper {
  margin-bottom: 80px;
  margin-top: 28px;
  text-align: center;
}
@media (max-width: 800px) {
  .home__icon-wrapper {
    margin-top: 10px;
  }
}

.home__ios-terminal {
  height: 200px;
  margin: 0 auto 56px auto;
}
@media (max-width: 800px) {
  .home__ios-terminal {
    width: 90%;
  }
}

.home__ios-terminal-body {
  height: 90px;
  overflow: scroll;
}

.home__paragraph {
  margin-left: auto;
  margin-right: auto;
}

.home__platform-icon {
  margin-right: 15px;
}
.home__platform-icon:last-child {
  margin-right: 0;
}

.home__snapshot-wrapper, .home__snapshot-wrapper-1, .home__snapshot-wrapper-3, .home__snapshot-wrapper-2 {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  position: absolute;
  top: 62px;
  width: 100%;
}
@media (max-width: 800px) {
  .home__snapshot-wrapper, .home__snapshot-wrapper-1, .home__snapshot-wrapper-3, .home__snapshot-wrapper-2 {
    top: 83px;
  }
}

.home__snapshot-wrapper-1, .home__snapshot-wrapper-3, .home__snapshot-wrapper-2 {
  -webkit-animation-duration: 50s;
          animation-duration: 50s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: marquee-1;
          animation-name: marquee-1;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

.home__snapshot-wrapper-2 {
  -webkit-animation-name: marquee-2;
          animation-name: marquee-2;
}

.home__snapshot-wrapper-3 {
  -webkit-animation-name: marquee-3;
          animation-name: marquee-3;
}

@-webkit-keyframes marquee-1 {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
  }
}

@keyframes marquee-1 {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
  }
}
@-webkit-keyframes marquee-2 {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}
@keyframes marquee-2 {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}
@-webkit-keyframes marquee-3 {
  0% {
    -webkit-transform: translateX(200%);
            transform: translateX(200%);
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}
@keyframes marquee-3 {
  0% {
    -webkit-transform: translateX(200%);
            transform: translateX(200%);
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}
@media (max-width: 1000px) {
  .home__snapshot {
    margin-right: 20px;
  }
}

.home__tab {
  margin-right: 34px;
}
.home__tab:last-child {
  margin-right: 0;
}
@media (max-width: 800px) {
  .home__tab {
    font-size: 18px;
    height: 50px;
    line-height: 50px;
    margin: 10px auto;
    width: 136px;
  }
  .home__tab:last-child {
    margin-right: auto;
  }
}

.home__tab-wrapper {
  margin-bottom: 43px;
  text-align: center;
}
@media (max-width: 800px) {
  .home__tab-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
  }
}

.home__cta-button {
  display: block;
  width: 260px;
}

.home__available-tables-button {
  margin: 57px auto 0 auto;
}

.home__things-to-know {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  margin: 0 auto;
  max-width: 900px;
}
@media (max-width: 800px) {
  .home__things-to-know {
    -ms-flex-direction: column;
        flex-direction: column;
  }
}

.home__thing-to-know {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: justify;
      justify-content: space-between;
  width: 260px;
}
@media (max-width: 800px) {
  .home__thing-to-know {
    width: 100%;
  }
}

.home__thing-to-know-heading {
  text-align: left;
}
@media (max-width: 800px) {
  .home__thing-to-know-heading {
    text-align: center;
  }
}

.home__thing-to-know-p {
  font-size: 16px;
  text-align: left;
}
@media (max-width: 800px) {
  .home__thing-to-know-p {
    text-align: center;
  }
}

.home__thing-to-know-button {
  width: 100%;
}
@media (max-width: 800px) {
  .home__thing-to-know-button {
    margin: 0 auto 30px;
    width: 85%;
  }
}

.home__community-events-header {
  max-width: 900px;
  width: auto;
}
@media (max-width: 800px) {
  .home__community-events-header {
    font-size: 14px;
  }
}

.home__project-cards {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin: 0 auto;
  max-width: 900px;
}

.home__project-card-wrapper {
  margin-top: 50px;
  width: 270px;
}
@media (max-width: 800px) {
  .home__project-card-wrapper {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    width: 95%;
  }
}

.home__additional-resources {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  margin: 50px auto 0;
  max-width: 1000px;
}
@media (max-width: 970px) {
  .home__additional-resources {
    -ms-flex-direction: column;
        flex-direction: column;
  }
}

.home__prominent-cta {
  margin-bottom: 30px;
}
