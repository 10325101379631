.monospace {
  color: #4a9dff;
  display: block;
  font-family: "Source Code Pro", monospace;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
}
@media (max-width: 800px) {
  .monospace {
    word-break: break-all;
  }
}
