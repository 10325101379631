.responsive-nav__wrapper {
  display: inline-block;
}

.responsive-nav {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  font-family: "Lato", sans-serif;
  -ms-flex-pack: end;
      justify-content: flex-end;
  height: 80px;
}
@media (max-width: 800px) {
  .responsive-nav {
    -ms-flex-align: end;
        align-items: flex-end;
    background-color: #fff;
    border-radius: 3px;
    -webkit-box-shadow: 0 2px 3px 0 rgba(32, 37, 50, 0.05), 0 5px 15px 0 rgba(32, 37, 50, 0.14), 0 21px 33px 0 rgba(48, 48, 91, 0.11);
            box-shadow: 0 2px 3px 0 rgba(32, 37, 50, 0.05), 0 5px 15px 0 rgba(32, 37, 50, 0.14), 0 21px 33px 0 rgba(48, 48, 91, 0.11);
    -ms-flex-direction: column;
        flex-direction: column;
    height: 250px;
    -ms-flex-pack: start;
        justify-content: flex-start;
    padding: 20px;
    width: 140px;
  }
}

.responsive-nav__link {
  margin-right: 3px;
}
@media (max-width: 800px) {
  .responsive-nav__link {
    margin: 0;
    padding: 12px;
  }
}

.responsive-nav__github-mark {
  margin-right: 5px;
}
