.footer {
  background-color: #f4f7fb;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin-top: 80px;
  padding: 58px;
}
@media (max-width: 800px) {
  .footer {
    -ms-flex-direction: column;
        flex-direction: column;
  }
}

.footer__li {
  list-style: none;
  margin-bottom: 8px;
}
.footer__li > a {
  color: rgba(32, 37, 50, 0.54);
  font-size: 18px;
  text-decoration: none;
}

.footer__linuxfoundation-st0 {
  fill: #003764;
}

.footer__linuxfoundation-st1 {
  fill: #009ADE;
}

.footer__facebook-open-source > span {
  color: #4d4e7f;
  font-size: 18px;
  margin-left: 8px;
}

.footer__copyright {
  color: #4a9dff;
  display: block;
  font-size: 18px;
  margin: 8px 0;
  text-decoration: none;
}

.footer__made-by-kolide {
  color: rgba(91, 74, 117, 0.3);
  font-size: 18px;
  font-weight: 700;
  margin-top: 95px;
}
.footer__made-by-kolide > a {
  color: rgba(91, 74, 117, 0.3);
}
@media (max-width: 800px) {
  .footer__made-by-kolide {
    font-size: 14px;
    margin-top: 10px;
  }
}

.footer__heart-text {
  color: #ff455b;
}

.footer__open-source-links {
  -ms-flex: 2 1;
      flex: 2 1;
}

.footer__resource-links {
  -ms-flex: 1 1;
      flex: 1 1;
}

.footer__meta {
  -ms-flex: 4 1;
      flex: 4 1;
}
