.osquery-table__header {
  background-color: #fff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.osquery-table__header--right {
  margin-left: 15px;
  white-space: nowrap;
}

.osquery-table__new-indicator {
  background-color: #4a9dff;
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  padding: 1px 4px;
  margin-left: 4px;
  position: relative;
  text-transform: uppercase;
  top: -2px;
}

.osquery-table__evented-icon {
  display: inline-block;
  margin-left: 10px;
  margin-right: 0;
  position: relative;
  top: 3px;
}

.osquery-table__evented {
  color: rgba(32, 37, 50, 0.54);
  font-size: 11px;
  font-weight: 700;
  margin-left: 5px;
}

.osquery-table__icon {
  margin-right: 14px;
}
.osquery-table__icon:last-child {
  margin-right: 0;
}

.osquery-table__table-description {
  color: rgba(32, 37, 50, 0.54);
  font-size: 14px;
  margin: 0 0 8px 0;
}

.osquery-table__table-data {
  color: rgba(24, 27, 38, 0.84);
  font-size: 14px;
}

.osquery-table__description {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
}

.osquery-table__table-name {
  margin-bottom: 5px;
}

.osquery-table__table-url {
  color: #4a9dff;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
}

.osquery-table__type {
  text-transform: uppercase;
}

.osquery-table table {
  border-collapse: collapse;
  width: 100%;
}
.osquery-table table thead {
  background-color: #eef2f7;
  color: rgba(24, 27, 38, 0.84);
  font-size: 11px;
  font-weight: 700;
  line-height: 15px;
}
.osquery-table table td {
  padding: 15px;
  text-align: left;
}
.osquery-table table tbody tr {
  border-bottom: 1px solid rgba(91, 74, 117, 0.1);
}
.osquery-table table tbody tr:last-child {
  border-bottom: 0;
}
