.section-break {
  background-color: #4a9dff;
  height: 2px;
  margin: 0 auto;
  opacity: 0.2;
  width: 300px;
}

.section-break--full-screen {
  background-color: transparent;
  background-image: url("../../images/gradient-line.svg");
  background-position: center center;
  background-repeat: no-repeat;
  height: 1px;
  margin: 0;
  opacity: 1;
  width: 100vw;
}
