.paragraph {
  color: rgba(24, 27, 38, 0.84);
  font-family: "Lato", sans-serif;
  font-size: 18px;
  line-height: 30px;
  margin: 16px auto;
  max-width: 600px;
  text-align: center;
}
@media (max-width: 800px) {
  .paragraph {
    font-size: 16px;
    line-height: 1.5;
    max-width: 90%;
  }
}

.paragraph--highlight {
  background-color: #f4f7fb;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #00125f;
  font-size: 16px;
  height: 50px;
  line-height: 1.5;
  padding-top: 12px;
  text-align: left;
  text-indent: 15px;
}
