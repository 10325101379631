.download-card {
  display: inline-block;
  text-align: left;
  word-wrap: break-word;
}

.download-card__name-wrapper {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.download-card__download-type {
  margin-left: 8px;
}

.download-card__package-name {
  color: #4a9dff;
  font-size: 14px;
  text-decoration: none;
}

.download-card .card-header {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 90px;
}

.download-card .card-body {
  color: rgba(32, 37, 50, 0.4);
  display: block;
  font-family: "Source Code Pro", monospace;
  font-size: 14px;
  word-break: break-all;
}
