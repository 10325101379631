.github-card__button {
  display: block;
}

.github-card__description {
  height: 68px;
  overflow: auto;
}

.github-card__footer {
  text-align: center;
}

.github-card__name {
  color: #00094c;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}

.github-card__star-count {
  color: #00094c;
  font-size: 16px;
  font-weight: 700;
  margin: 3px 0 0;
}
