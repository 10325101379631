.app {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  min-height: 100vh;
}

.app__header {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin: 0 auto;
  max-width: 1100px;
  padding: 0 10px;
  width: 100%;
}
@media (max-width: 800px) {
  .app__header {
    padding: 20px 10px;
  }
}

.app__home-link {
  color: #00094c;
  text-decoration: none;
}

.app__logo {
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 100%;
}

@media (max-width: 800px) {
  .app__nav {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 1;
  }
}

.app__main {
  -ms-flex: 1 1;
      flex: 1 1;
}
