.heading2 {
  color: #4a9dff;
  font-family: "Lato", sans-serif;
  font-size: 32px;
  font-weight: 900;
  line-height: 40px;
  margin: 0 0 16px 0;
  text-align: center;
}
@media (max-width: 800px) {
  .heading2 {
    font-size: 20px;
    line-height: 24px;
  }
}
