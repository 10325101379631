.event-listing {
  display: -ms-flexbox;
  display: flex;
  font-size: 18px;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin: 30px auto;
  max-width: 900px;
  width: auto;
}
@media (max-width: 800px) {
  .event-listing {
    display: block;
    font-size: 14px;
  }
}

.event-listing__dates {
  color: rgba(0, 18, 95, 0.8);
  -ms-flex: 1 1;
      flex: 1 1;
}

.event-listing__title {
  color: #4a9dff;
  -ms-flex: 2 1;
      flex: 2 1;
  font-weight: bold;
  text-decoration: none;
}
@media (max-width: 800px) {
  .event-listing__title {
    display: block;
    margin: 3px 0;
  }
}

.event-listing__location {
  color: rgba(0, 18, 95, 0.54);
  -ms-flex: 1 1;
      flex: 1 1;
  text-align: right;
}
@media (max-width: 800px) {
  .event-listing__location {
    text-align: left;
  }
}
