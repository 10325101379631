body {
  font-family: "Lato", sans-serif;
  margin: 0;
  min-height: 100vh;
  padding: 0;
}

#root {
  min-height: 100vh;
}

ul, ol {
  padding: 0;
}
