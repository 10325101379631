.downloads-page__page-wrapper {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}
@media (max-width: 800px) {
  .downloads-page__page-wrapper {
    margin: 0 15px;
  }
}

.downloads-page__dropdown {
  margin: 0 auto;
  text-align: left;
  width: 260px;
}

.downloads-page__download-card {
  margin-top: 36px;
  width: 270px;
}
.downloads-page__download-card:nth-child(even) {
  margin-left: 36px;
}
@media (max-width: 610px) {
  .downloads-page__download-card:nth-child(even) {
    margin-left: 36px;
    margin-right: 36px;
  }
}

.downloads-page__downloads-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: center;
      justify-content: center;
  width: 600px;
}
@media (max-width: 800px) {
  .downloads-page__downloads-wrapper {
    width: 100%;
  }
}

.downloads-page__tab {
  padding: 14px 30px;
}

.downloads-page__tab--official {
  margin-right: 24px;
}

.downloads-page__tab--arm64 {
  margin-left: 24px;
}

.downloads-page__pill-wrapper {
  margin-top: 32px;
}

.downloads-page__osquery-version {
  margin: 24px 0 12px 0;
}

.downloads-page__alternative-install-section {
  text-align: center;
}

.downloads-page__install-icon {
  height: 60px;
  margin-top: 39px;
  margin-bottom: 30px;
  width: 60px;
}

.downloads-page__install-pill {
  margin-right: 19px;
}
.downloads-page__install-pill:last-child {
  margin-right: 0;
}
@media (max-width: 800px) {
  .downloads-page__install-pill {
    margin: 5px;
    width: 140px;
  }
}

.downloads-page__terminal {
  background-color: #f4f7fb;
  -webkit-box-shadow: none;
          box-shadow: none;
  text-align: left;
  width: 789px;
}
@media (max-width: 800px) {
  .downloads-page__terminal {
    width: 100%;
  }
}

.downloads-page__terminal-body {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 15px 20px;
}
@media (max-width: 800px) {
  .downloads-page__terminal-body {
    width: 100%;
  }
}
.downloads-page__terminal-body .monospace {
  color: rgb(32, 37, 50);
  font-size: 14px;
  font-weight: 600;
}
.downloads-page__terminal-body .unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.downloads-page__packages-section {
  text-align: center;
}

.downloads-page__title-section {
  margin-top: 107px;
  max-width: 600px;
  text-align: center;
}

div.downloads-page__section-break {
  margin-bottom: 50px;
  margin-top: 50px;
}
