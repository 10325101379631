.prominent-cta {
  text-align: center;
  text-decoration: none;
}

.prominent-cta__circle {
  -ms-flex-align: center;
      align-items: center;
  background-color: #f4f7fb;
  border-radius: 300px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 300px;
  -ms-flex-pack: center;
      justify-content: center;
  margin-bottom: 36px;
  width: 300px;
}

.prominent-cta > span {
  color: #4a9dff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
}
