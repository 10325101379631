.checkbox {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
}

.checkbox__input {
  margin: 0;
  position: absolute;
  visibility: hidden;
  z-index: -1;
}
.checkbox__input:checked + .checkbox__tick::after {
  background-color: #4a9dff;
  border: solid 2px #4a9dff;
}
.checkbox__input:checked + .checkbox__tick::before {
  border: 2px solid #fff;
  border-left: 0;
  border-top: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  content: "";
  display: table;
  height: 13px;
  left: 50%;
  margin: -8px 0 0 -4px;
  position: absolute;
  top: 50%;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
  width: 7px;
}
.checkbox__input:checked + .checkbox__tick.checkbox__tick--semi::before {
  border-bottom: 0;
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}

.checkbox__tick {
  cursor: pointer;
  display: inline-block;
  height: 20px;
  left: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-10px);
      -ms-transform: translateY(-10px);
          transform: translateY(-10px);
  width: 20px;
}
.checkbox__tick::after {
  background-color: #fff;
  border: 2px solid rgba(32, 37, 50, 0.14);
  border-radius: 2px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  content: "";
  display: block;
  height: 20px;
  -webkit-transition: border 75ms ease-in-out, background 75ms ease-in-out;
  -o-transition: border 75ms ease-in-out, background 75ms ease-in-out;
  transition: border 75ms ease-in-out, background 75ms ease-in-out;
  visibility: visible;
  width: 20px;
}

.checkbox__tick--disabled::after {
  background-color: rgba(32, 37, 50, 0.14);
  border: 0;
}

.checkbox__label {
  color: rgba(73, 83, 116, 0.84);
  font-size: 18px;
  line-height: 20px;
  padding-left: 25px;
}
