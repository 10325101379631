.button {
  background-color: #f9fbfe;
  border: 1px solid rgba(74, 157, 255, 0.6);
  border-radius: 4px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #4a9dff;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  outline: none;
  padding: 9px 12px 12px;
  text-align: center;
  text-decoration: none;
  -webkit-transition: background-color 0.2s, color 0.2s;
  -o-transition: background-color 0.2s, color 0.2s;
  transition: background-color 0.2s, color 0.2s;
}
.button:hover {
  background-color: #4a9dff;
  color: #fff;
}

.button__link {
  background: transparent;
  border: 0;
  padding: 0;
}
.button__link:hover {
  background: transparent;
  color: #2b84ec;
  text-decoration: underline;
}
