.terminal-snapshot__body {
  color: #5b4a75;
  font-family: "Source Code Pro", monospace;
  font-size: 13px;
  padding-bottom: 13px;
  padding-left: 12px;
  padding-right: 29px;
  padding-top: 7px;
  text-align: left;
}
.terminal-snapshot__header {
  -ms-flex-align: center;
      align-items: center;
  background-color: rgba(91, 74, 117, 0.16);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #00125f;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  font-weight: 900;
  height: 40px;
  padding-right: 29px;
}
.terminal-snapshot__oval {
  background-color: #a596ff;
  border: solid 2px #00125f;
  border-radius: 6px;
  bottom: 7px;
  height: 6px;
  position: absolute;
  right: 7px;
  width: 6px;
}
.terminal-snapshot__wrapper {
  background-color: #fff;
  border-radius: 10px;
  -webkit-box-shadow: 0 6px 14px 0 rgba(0, 18, 95, 0.16);
          box-shadow: 0 6px 14px 0 rgba(0, 18, 95, 0.16);
  display: inline-block;
  position: relative;
}
