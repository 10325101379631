.toc-entry {
  border-left: 4px solid transparent;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  list-style: none;
  padding-left: 10px;
  width: 190px;
}

.toc-entry__link {
  color: rgba(0, 18, 95, 0.6);
  font-family: "Lato", sans-serif;
  font-size: 14px;
  height: 26px;
  letter-spacing: -0.3px;
  line-height: 26px;
  text-decoration: none;
}

.toc-entry__new-indicator {
  background-color: #4a9dff;
  border-radius: 3px;
  color: #fff;
  font-size: 9px;
  font-weight: 700;
  padding: 1px 4px;
  position: relative;
  margin-left: 5px;
  text-transform: uppercase;
  top: -1px;
}

.toc-entry--active {
  background-color: rgba(0, 18, 95, 0.06);
  border-bottom-right-radius: 4px;
  border-left-color: #4a9dff;
  border-top-right-radius: 4px;
}

.toc-entry--active > .toc-entry__link {
  color: rgba(0, 18, 95, 0.9);
}
